import React, { useState } from 'react'

import {
    Center,
    Input,
    InputGroup,
    Select,
} from '@chakra-ui/react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Box,
    Text,
} from '@chakra-ui/react'
import ModalLoader from './ModalLoader';
import { toast } from 'react-toastify';
import * as api from '../services/api_service'

function ConnectExchange() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [exchange, setExchange] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    async function connectEx() {
        setIsFetching(true)
        try {
            await api.linkAccount({
                email: email,
                password: password,
                exchange: exchange
            });
            window.location.reload();
        } catch (error) {
            console.log(error.response.data)
            toast.error(error.response.data.message)
            setIsFetching(false)
        }
    }

    return (
        <>
            <Button size={'sm'} colorScheme='green' onClick={onOpen}>Connect Exchange +</Button>

            <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={'rgb(20,28,35)'} color={'whiteAlpha.800'}>
                    <ModalCloseButton />
                    <ModalBody pt={10} pb={2}>
                        {
                            !isFetching ?
                                <>
                                    <Box mb={7}>
                                        <Text fontWeight={'bold'} mb={2}>Connect exchange</Text>
                                    </Box>


                                    <Text mb={2} fontSize={13}>Select Exchange</Text>
                                    <InputGroup mb={6}>
                                        <Select placeholder='Select' onChange={(e) => setExchange(e.target.value)}>
                                            <option style={{ color: '#000' }} value="1">Capitalelite.io</option>
                                            <option style={{ color: '#000' }} value="0">binance.com</option>
                                        </Select>
                                    </InputGroup>

                                    <Text mb={2} fontSize={13}>Email</Text>
                                    <InputGroup mb={7}>
                                        <Input type='email' placeholder='Exchange email/username' onChange={(e) => setEmail(e.target.value)} />
                                    </InputGroup>

                                    <Text mb={2} fontSize={13}>Password</Text>
                                    <InputGroup mb={8}>
                                        <Input type='password' placeholder='Exchange password' onChange={(e) => setPassword(e.target.value)} />
                                    </InputGroup>


                                    <Center mb={10}>
                                        <Button variant={'outline'} colorScheme='green' onClick={() => connectEx()} isDisabled={email === '' || password === '' || exchange === '' || isFetching}>Connect</Button>
                                    </Center>
                                </> :
                                <ModalLoader />
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ConnectExchange