import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/UseAuth';
import { Box, Center, Flex, FormErrorMessage, Image, Input, InputGroup, InputLeftElement, Link, Spinner } from '@chakra-ui/react'
import { Card, CardBody, Text, FormControl, FormLabel, Button } from '@chakra-ui/react'
// import { kBgColor } from '../../utils/global'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { MdOutlineEmail } from 'react-icons/md'
import { toast } from 'react-toastify';
// import { BsRobot } from 'react-icons/bs';
import logoImg from '../../assets/images/logo.png'
import * as api from "../../services/api_service"

// const authCardStyle = {
//   position: 'absolute',
//   width: '420px',
//   height: '420px'
// }

function ResetPassword() {

  const navigate = useNavigate();
  const { isAuthenticated, authError, setAuthError, token } = useAuth();

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState([])
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {

    if (token !== null) {
      navigate("/user", { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token])

  async function passwordReset() {
    setEmailError([])
    setIsSending(true);

    try {
      const resp = await api.passwordReset({
        email: email
      });      
      setIsSending(false)
      setEmail('');
      toast.success(resp.data.message, {
        autoClose: 10000
      });
    } catch (error) {
      console.log("passwordReset error:", error.response.data)
      toast.error(error.response.data.message)
      setIsSending(false)
    }
  }

  return (
    <Box pt={{ base: '120px', md: '220px' }}>

      <Center p={5}>

        <Box pos={'relative'} width={{ base: '100%', md: '500px' }}>

          <Center mb={5}>
            <Flex color={'blue.800'}>
              {/* <Icon as={BsRobot} w={9} h={9} /> */}
              <Image src={logoImg} w={'20px'} />
              <Text ml={4} mt={1} fontSize={'20px'} fontWeight={'bold'} textDecor={'underline'} display={{ base: 'none', md: 'block' }}>{process.env.REACT_APP_NAME}</Text>
            </Flex>
          </Center>

          {/* <Image src={profileImg} style={authCardStyle} left={{ base: '-150px', md: '-350px' }} top={{ base: '-110px', md: '-150px' }} /> */}

          <Card bgColor={'whiteAlpha.100'} color={'blackAlpha.800'} shadow={'2xl'} pos={'relative'} zIndex={1}>
            {/* <CardHeader>
              <Heading size='md' textDecor={'underline'}>Sign in</Heading>
            </CardHeader> */}
            <CardBody>

              <Box>
                <Box fontWeight={'bold'} color='blue.600' mb={'5'} textDecoration={'underline'}>Reset Password</Box>
                <FormControl mb={'20px'} isInvalid={emailError.length > 0}>
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<MdOutlineEmail color='gray.300' />}
                    />
                    <Input type='email' value={email} placeholder='example@company.com' onChange={(e) => {
                      setEmail(e.target.value); Object.keys(authError).length !== 0 && setAuthError({}); setEmailError([])
                    }} />
                  </InputGroup>
                  {emailError.map((err, k) => (<FormErrorMessage key={k}>{err}</FormErrorMessage>))}
                </FormControl>

                <FormControl mb={5} isInvalid={Object.keys(authError).length > 0} isDisabled={isSending}>
                  {!isSending ?
                    <Button colorScheme='blue' onClick={passwordReset} variant={'solid'} w={'full'}>Submit</Button> :
                    <Button colorScheme='blue' variant={'solid'} w={'full'}><Spinner /></Button>
                  }
                  {Object.keys(authError).length > 0 && <FormErrorMessage>{authError.message}</FormErrorMessage>}
                </FormControl>


              </Box>

            </CardBody>
          </Card>
          <Center>
            <Box mt={10}>
              <Text mb={2} fontSize={'sm'}>Go back to <Link as={RouterLink} to={'/auth/login'} color='blue.400' fontWeight={'bold'} >Login</Link></Text>
              {/* <Text fontSize={'smaller'}><Link href={process.env.REACT_APP_HOME_URL} as={RouterLink} color='cyan.400'>Goto Home</Link></Text> */}
            </Box>
          </Center>
        </Box>
      </Center>
    </Box>
  )
}

export default ResetPassword