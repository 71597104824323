import { Box, Button, Center, Circle, Flex, Icon, SimpleGrid, Text, Image, Input, Skeleton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { BsRobot } from 'react-icons/bs';
import { MdArrowCircleRight } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { currencyFormat } from '../../utils/helper';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';

import btcIcon from '../../assets/images/coins/btc.svg'
import ethIcon from '../../assets/images/coins/eth.svg'
import solIcon from '../../assets/images/coins/sol.svg'
import ltcIcon from '../../assets/images/coins/ltc.svg'
import usdtIcon from '../../assets/images/coins/usdt.svg'
import bnbIcon from '../../assets/images/coins/bnb.svg'
import shibIcon from '../../assets/images/coins/shib.svg'
import ActiveBotInfo from '../../components/ActiveBotInfo';
import CopyBot from '../../components/CopyBot';
import * as api from '../../services/api_service'

function Bots() {

    const [searchParams, setSearchParams] = useSearchParams();
    const queryParam = searchParams.get("to") || 'active';
    const tTypeName = queryParam.toLowerCase();
    const [tab, setTab] = useState(tTypeName);

    const chartData = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 1400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 3800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 4800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    const [isFetching, setIsFetching] = useState(true);
    const [bots, setBots] = useState([]);
    const [myBots, setMyBots] = useState([]);
    const [exchanges, setExchanges] = useState([]);

    useEffect(() => {
        async function fetchBots() {
            setIsFetching(true);

            try {
                const resp = await Promise.all([
                    api.getBotTypes(),
                    api.getLinkedUsers(),
                    api.getUserBots(),
                ]);
                setBots(resp[0].data)
                setExchanges(resp[1].data.data)
                setMyBots(resp[2].data.data)
                setIsFetching(false);
            } catch (error) {
                setIsFetching(false);
                console.log(error)
                // toast.error(error[0].response.data);
            }
        }

        fetchBots();
    }, []);

    return (
        <>
            <Flex justifyContent={'space-between'} mb={3} mt={10}>
                <Box fontSize={'23px'} mt={0} fontWeight={'semibold'} mb={8}>Bots</Box>
                <Flex>
                    <Box mr={5}>
                        <Box borderBottom={tab === 'active' ? 'solid 2px #fff' : 'none'} color={tab === 'active' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => { setTab('active'); setSearchParams('to=active') }} fontSize={14}>Active Bots</Box>
                    </Box>
                    <Box mr={5}>
                        <Box borderBottom={tab === 'explore' ? 'solid 2px #fff' : 'none'} color={tab === 'explore' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => { setTab('explore'); setSearchParams('to=explore') }} fontSize={14}>Explore Bot Presets</Box>
                    </Box>
                </Flex>
            </Flex>

            {
                tab === 'active' ?
                    (
                        !isFetching ?
                            myBots.length > 0 ?
                                <>
                                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                        {
                                            myBots.map((e, k) => (
                                                <Box border={'solid 1px rgba(180,194,206, 0.3)'} p={5} borderLeft={'solid 3px rgb(39, 98, 180)'} key={k}>
                                                    <Flex justifyContent={'space-between'} mb={2}>
                                                        <Flex gap={3} mb={4}>
                                                            <Circle mt={2} bgColor={'blue.500'} w={2} h={2}> </Circle>
                                                            <Box>
                                                                <Text>Active</Text>
                                                                <Text fontSize={'smaller'}>{e.created_at}</Text>
                                                            </Box>
                                                        </Flex>
                                                        <ActiveBotInfo bot={e} />
                                                    </Flex>

                                                    <Flex gap={3} mb={6}>
                                                        <Icon as={BsRobot} w={10} h={10} />
                                                        <Box>
                                                            <Text>{e.bot_type.name}</Text>
                                                            <Text fontSize={10} color={'rgba(180,194,206, 0.6)'} fontWeight={'bold'}>DCA Long Strategy</Text>
                                                        </Box>
                                                    </Flex>

                                                    <Flex gap={5} mb={5}>
                                                        <Box>
                                                            <Text fontSize={14} fontWeight={'bold'} >Exchange</Text>
                                                            <Box bgColor={'rgb(47,64,80)'} display={'inline-block'} pl={2} pr={2} pt={1} pb={1} borderRadius={10}>{e.linked_user.site_name}</Box>
                                                        </Box>

                                                        <Box>
                                                            <Text fontSize={14} fontWeight={'bold'} >Max equity</Text>
                                                            <Box bgColor={'rgb(47,64,80)'} display={'inline-block'} pl={2} pr={2} pt={1} pb={1} borderRadius={10}>{currencyFormat(e.max_balance)}</Box>
                                                        </Box>

                                                        <Box>
                                                            <Text fontSize={14} fontWeight={'bold'} >Expected APY</Text>
                                                            <Box bgColor={'rgb(47,64,80)'} display={'inline-block'} pl={2} pr={2} pt={1} pb={1} borderRadius={10} color={'#38a169'}>+{e.bot_type.roi}%</Box>
                                                        </Box>
                                                    </Flex>

                                                    <Box w={'100%'} h={'60px'}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <AreaChart width={300} height={100} data={chartData}>
                                                                <Area type="monotone" dataKey="pv" stroke="#38a169" strokeWidth={2} dot={false} fill='rgba(21,43,48,0.5)' />
                                                            </AreaChart>
                                                        </ResponsiveContainer>
                                                    </Box>
                                                </Box>
                                            ))
                                        }
                                    </SimpleGrid>
                                </> :
                                <Box w={'100%'} h={'500px'} bgGradient='linear(to-l, rgb(20,28,35), rgb(22,44,47))'>
                                    <Center h={'inherit'}>
                                        <Box>
                                            <Center mb={5}>
                                                <Text>
                                                    No Active Bot Found. <br /> You can search for bot presets with the link below.
                                                </Text>
                                            </Center>
                                            <Button colorScheme='cyan' color={'white'} onClick={() => { setTab('explore'); setSearchParams('to=explore'); }}>Explore Presets &nbsp; <Icon as={MdArrowCircleRight} /></Button>
                                        </Box>
                                    </Center>
                                </Box>
                            :
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {
                                    Array.from(Array(4)).map((e, k) => (
                                        <Skeleton w={'100%'} h={'200px'} key={k} />
                                    ))
                                }
                            </SimpleGrid>
                    )

                    :

                    <>
                        <Box>
                            <Flex justifyContent={'space-between'} mb={7}>
                                <Input variant='flushed' placeholder='Search Bot' w={'200px'} />
                                {/* <Link as={RouterLink} mt={1} fontSize={'15px'} color='blue.500'>Explore More</Link> */}
                            </Flex>

                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                {
                                    !isFetching ?
                                        bots.map((e, k) => (
                                            <Box border={'solid 1px rgba(180,194,206, 0.3)'} p={5} shadow={'lg'} borderRadius={5} key={k}>
                                                <Flex gap={3} mb={7}>
                                                    <Icon as={BsRobot} w={10} h={10} />
                                                    <Box>
                                                        <Text>{e.name}</Text>
                                                        <Text fontSize={10} color={'rgba(180,194,206, 0.6)'} fontWeight={'bold'}>DCA Long Strategy</Text>
                                                    </Box>
                                                </Flex>

                                                <Flex justifyContent={'space-between'} mb={6}>
                                                    <Box w={'25%'}>
                                                        <Text fontSize={13} color={'rgba(180,194,216, 0.6)'} fontWeight={'bold'} mb={1}>Expected APY</Text>
                                                        <Text color={'green.500'} fontSize={'22px'}>+{e.roi}%</Text>
                                                    </Box>

                                                    <Box w={'75%'} h={'inherit'}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <AreaChart width={300} height={100} data={chartData}>
                                                                <Area type="monotone" dataKey="pv" stroke="#38a169" strokeWidth={2} dot={false} fill='rgba(21,43,48,0.5)' />
                                                            </AreaChart>
                                                        </ResponsiveContainer>
                                                    </Box>
                                                </Flex>

                                                <Flex gap={2} mb={7}>
                                                    <Text fontSize={13}>Coins</Text>
                                                    <Flex>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1}>
                                                            <Image src={btcIcon} w={3} h={3} />
                                                        </Box>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1} ml={'-4px'}>
                                                            <Image src={ethIcon} w={3} h={3} />
                                                        </Box>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1} ml={'-4px'}>
                                                            <Image src={solIcon} w={3} h={3} />
                                                        </Box>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1} ml={'-4px'}>
                                                            <Image src={ltcIcon} w={3} h={3} />
                                                        </Box>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1} ml={'-4px'}>
                                                            <Image src={usdtIcon} w={3} h={3} />
                                                        </Box>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1} ml={'-4px'}>
                                                            <Image src={bnbIcon} w={3} h={3} />
                                                        </Box>
                                                        <Box bgColor={'rgb(47,64,80)'} borderRadius={'full'} p={1} ml={'-4px'}>
                                                            <Image src={shibIcon} w={3} h={3} />
                                                        </Box>
                                                    </Flex>
                                                    <Text fontSize={10} mt={1}>+59</Text>
                                                </Flex>

                                                <CopyBot bot={e} exchanges={exchanges} />
                                            </Box>
                                        ))
                                        :
                                        Array.from(Array(4)).map((e, k) => (
                                            <Skeleton w={'100%'} h={'200px'} key={k} />
                                        ))
                                }
                            </SimpleGrid>
                        </Box>

                        <Box h={100} />
                    </>
            }
        </>
    )
}

export default Bots