import { Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'

function TradePNL({ order }) {
    // const { user, setUser } = UseAuth();

    const [isProfit, setIsProfit] = useState(false);
    const [profit, setProfit] = useState();
    const _timer = useRef();
    // console.log(order.symbol.name, isProfit)

    useEffect(() => {
        setIsProfit(Math.floor(Math.random() * 5) > 2);
        setProfit(isProfit ? Math.floor(Math.random() * 23) : -(Math.floor(Math.random() * 90)));

        _timer.current = setInterval(() => {
            let _isProfit = Math.floor(Math.random() * 5) > 2;
            setIsProfit(_isProfit)
            setProfit(_isProfit ? Math.floor(Math.random() * 23) : -(Math.floor(Math.random() * 90)));
        }, (Math.random() * (1000 - 700) + 700));

        const starttime = new Date(order.created_at);
        const endtime = new Date(order.end_time);
        const diffCurrent = (order.now - 3600) - (starttime.getTime() / 1000);
        const diffTotal = (endtime.getTime() - starttime.getTime()) / 1000;
        console.log('backend now:', order.now - 3600)
        console.log('js now:', (new Date().getTime() / 1000) - 3600)
        console.log('created at:', new Date(order.created_at).getTime());
        console.log('End time', new Date(order.end_time).getTime());
        console.log('difference current', diffCurrent)
        console.log('difference total', diffTotal)

        return () => {
            clearInterval(_timer.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function resolvePNL() {
        if (order) {
            const starttime = new Date(order.created_at);
            const endtime = new Date(order.end_time);
            const diffCurrent = (order.now - 3600) - (starttime.getTime() / 1000);
            const diffTotal = (endtime.getTime() - starttime.getTime()) / 1000;

            const maxProfit = (order.profit * order.amount) / 100;
            const _pnl = (diffCurrent / diffTotal) * maxProfit * (isProfit ? 1 : Math.random());

            if (diffCurrent < diffTotal) {
                return {
                    pnl: parseFloat((_pnl).toFixed(3)),
                    roe: parseFloat(((_pnl / order.amount) * 100).toFixed(2))
                }
            } else {
                return {
                    pnl: maxProfit.toFixed(3),
                    roe: order.profit
                }
            }
        } else {
            return {
                pnl: 10,
                roe: 10
            }
        }

    }

    return (
        <>
            <Text color={(resolvePNL().roe > 0 ) ? 'green.500' : (resolvePNL().roe < 0 ) ? 'red.500' : 'yellow.600'}>
                {resolvePNL().pnl}
            </Text>
            {profit && <></>}
        </>
    )
}

export default TradePNL