import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {FiSettings} from 'react-icons/fi'
// import { BsRobot } from 'react-icons/bs'
import style from '../assets/modules/navheader.module.css'
import logoImg from '../assets/images/logo.png'

function Navbar() {
const navigate = useNavigate();
  return (
    <Box pl={5} pr={5} pt={6} pb={5} borderBottom={'solid 1px rgba(200,200,200,0.2)'}>
        <Flex justifyContent={'space-between'}>
            <Flex>
                {/* <Icon as={BsRobot} w={6} h={6} /> */}
                <Image src={logoImg} w={'15px'} />
                <Text ml={4} fontWeight={'bold'} textDecor={'underline'} display={{base: 'none', md: 'block'}} onClick={() => navigate('/user/dashboard')} cursor={'pointer'}>{process.env.REACT_APP_NAME}</Text>
            </Flex>
            <Flex justifyContent={'space-between'} gap={{base: 4, md: 5}} fontSize={{base: 14, md: 15}}>
                <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/dashboard'}>Overview</NavLink>
                <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/bots'}>Bots</NavLink>
                <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/exchanges'}>Exchanges</NavLink>
                <NavLink className={({ isActive }) => (isActive ? style.active : undefined)} to={'/user/settings'}><Icon as={FiSettings} w={{base: 5, md: 6}} h={{base: 5, md: 6}} /></NavLink>
            </Flex>
        </Flex>
    </Box>
  )
}

export default Navbar